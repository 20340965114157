(function ($) {
  Drupal.behaviors.swipeBoxBehavior = {
    attach: function(context, settings){
      $('.node a[href*=".jpg"], .node a[href*=".jpeg"], .node a[href*=".png"], .node a[href*=".gif"]').swipebox({hideBarsDelay: 0});
    }
  };

  Drupal.behaviors.googleMapBehavior = {
    attach: function(context, settings){
      $('.field--google-maps-adress', context).once('googleMap', function(){
        var $mapWrapper = $(this);
        var address = $mapWrapper.text();
        $mapWrapper.empty();
        var locations = [];
        var bounds = new google.maps.LatLngBounds();
        getLatLong(address);

        var mapOptions = {
          zoom: 1,
          maxZoom: 17,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          panControl: false,
          zoomControl: false,
          streetViewControl: false,
        };

        function getLatLong(address) {
          address = address.trim().replace(/(\r\n|\n|\r)/gm," ");
          var geo = new google.maps.Geocoder;
          geo.geocode({
            'address': address,
            region: 'no'
          }, function(results, status) {
            if (status.toLowerCase() == 'ok') {
              locations.push(results[0].geometry.location);
              initializeMap();
            }
          });
        }

        function convertLatLong(location) {
          location = location.toString().replace('(', '').replace(')', '').replace(' ', '');
          location = location.split(',');
          var latLong = {lat: location[0], long: location[1]};
          return latLong;
        }

        function initializeMap() {
          var map = new google.maps.Map($mapWrapper[0], mapOptions);

          var marker, i;
          for (i = 0; i < locations.length; i++) {
            var location = locations[i];
            var latLong = convertLatLong(location);
            var myLatLng = new google.maps.LatLng(latLong.lat, latLong.long);
            marker = new google.maps.Marker({
              position: myLatLng,
              map: map,
              animation: google.maps.Animation.DROP,
              title: location['Sleep at Sea']
              // icon: location.protocol + '//' + location.host + Drupal.settings.pathToTheme + '/img/marker.png'
            });

            google.maps.event.addListener(marker, "click", function () {
              window.open("https://maps.google.com/maps?daddr=" + latLong.lat + "," + latLong.long + "&hl=nl");
            });

            // Extend the bounds to include each marker's position
            bounds.extend(marker.position);
          }
          map.fitBounds(bounds);
          var zoom = map.getZoom();
          // map.setZoom(zoom > 1 ? 1 : zoom);
        }
      });
    }
  };

  Drupal.behaviors.menuToggleBehavior = {
    attach: function(context, settings){
      $('.menu-toggle', context).once('menuToggle', function(){
        var $menuToggle = $(this),
        $menu = $('.main-menu');

        $menu.slideUp(0);

        $menuToggle.click(function(e) {
          e.preventDefault();
          $menu.slideToggle(300);
        });
      });
    }
  };

  Drupal.behaviors.heroBehavior = {
    attach: function(context, settings){
      $('.l-hero', context).once('hero', function(){
        $('body').addClass('has-hero');
      });
    }
  };

Drupal.behaviors.sliderBehavior = {
  attach: function(context, settings){
    $('.paragraphs-items-field-slides', context).once('slider', function(){
      var $nextButton = $('<a href="#" class="field--slides-nav field--slides-nav--next">next</a>');
      var $previousButton = $('<a href="#" class="field--slides-nav field--slides-nav--previous">previous</a>');
      $(this).append([$previousButton, $nextButton]);
      $nextButton.click(nextSlide);
      $previousButton.click(previousSlide);

      var $slides = $('.field--slides');
      var currentIndex = 0;
      $slides.filter(':gt(0)').fadeOut(0);
      var slideCount = $slides.length;

      function nextSlide(event) {
        event.preventDefault();
        currentIndex++;
        slide();
      }
      function previousSlide(event) {
        event.preventDefault();
        currentIndex--;
        slide();
      }

      function slide() {
        if(currentIndex >= slideCount) currentIndex = 0;
        if(currentIndex < 0) currentIndex = slideCount - 1;
        $slides.filter(':not(:eq('+currentIndex+'))').fadeOut();
        $slides.eq(currentIndex).fadeIn();
      }
    });
  }
};




})(jQuery);
